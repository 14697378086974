import get from 'lodash/get';
import { createSetter, getSnapshot, useSubscribedState } from 'nordic/page/store';

import findPolycardComponent from '../../../utils/find-polycard-components';
import { TYPE_RESULTS, CARD_TYPE_ID } from '../../../constants';

const { BILLBOARD_INTERVENTION, FACETED_SEARCH_INTERVENTION, FOUR_STARS_INTERVENTION, CART_INTERVENTION } =
  TYPE_RESULTS;

const { POLYCARD_ITEM_ID, LEGACYCARD_ITEM_ID } = CARD_TYPE_ID;

export const useSearch = () => useSubscribedState()?.search;

export const useSameItemList = () => useSearch().sameItemList;

export const useSearchShops = () => useSearch().shops;

export const getSearch = () => getSnapshot().search;

const getCards = () => getSearch().cards;

export const useCarouselItem = (itemIndex) => useSearch().carousel.values[itemIndex];

export const getCarouselItem = (itemIndex) => getSearch().carousel.values[itemIndex];

export const setCarouselItemFilters = createSetter(
  (
    {
      search: {
        carousel: { values },
      },
    },
    payload,
  ) => {
    values[payload.itemIndex].filters = payload.filters;
  },
);

export const setCarouselItemUniqueKey = createSetter(
  (
    {
      search: {
        carousel: { values },
      },
    },
    payload,
  ) => {
    values[payload.itemIndex].uniqueKey = payload.uniqueKey;
  },
);

export const setListingDisclaimerCurrentLayout = createSetter(({ search: { listing_disclaimer } }, payload) => {
  listing_disclaimer.currentLayout = payload;
});

export const pushToCards = createSetter(({ search }, payload) => {
  search.cards.push(...payload);
});

const isAlternativeValidForItem = (item, alternative) =>
  !alternative || item?.product?.[alternative.prop]?.find((currentAlt) => currentAlt.id === alternative.id);

export const getItemById = (itemId, state = undefined, { alternative, internal = false } = {}) => {
  const { results, billboard } = state || getSearch();

  const resultItem = results.find((it) => {
    if (it.id === BILLBOARD_INTERVENTION || it.id === FACETED_SEARCH_INTERVENTION) {
      return it.contents && isAlternativeValidForItem(it, alternative);
    }

    if ((it.id === CART_INTERVENTION || it.id === FOUR_STARS_INTERVENTION) && !internal) {
      if (state) {
        state.cards.push(...it.dynamic_access.cards);
      } else {
        pushToCards(it.dynamic_access.cards);
      }
    }

    const item = it?.polycard?.metadata?.id === itemId || it?.id === itemId;

    return item && isAlternativeValidForItem(it, alternative);
  });

  if (!resultItem && billboard && billboard.items) {
    return billboard.items.find((it) => it.id === itemId && isAlternativeValidForItem(it, alternative));
  }

  const card = (state ? state.cards : getCards()).find(
    (item) => item.id === itemId && isAlternativeValidForItem(item, alternative),
  );

  if (!resultItem && card) {
    return card.content.add_to_cart_item;
  }

  return resultItem;
};

const updatePolyCartValues = (item, mainProperty, secondProperty, value) => {
  const addToCartPoly = findPolycardComponent(item, 'add_to_cart');

  if (addToCartPoly?.add_to_cart?.[mainProperty]) {
    addToCartPoly.add_to_cart[mainProperty][secondProperty] = value;
  }
};

const updateQuantityRepeatProduct = (item, value, results) => {
  results.forEach((element) => {
    const addToCartPoly = findPolycardComponent(element, 'add_to_cart')?.add_to_cart;

    if (element.polycard?.metadata.id === item.polycard?.metadata.id) {
      addToCartPoly.cart_status.quantity = value;
    }
  });
};

const findSameQuantity = (quantity) => (finding) => finding.quantity === quantity;

const getProperty = (obj, path) => path.split('.').reduce((acc, part) => acc && acc[part], obj);

export const getRepeatItemIntervention = (results = []) => {
  const cartInterventions = results.filter((item) => item.id === CART_INTERVENTION);

  if (cartInterventions.length >= 2) {
    const allInterventionCards = cartInterventions.flatMap(({ dynamic_access }) => dynamic_access?.cards || []);

    const repeatCards = allInterventionCards.filter((obj, index) => {
      const prop = obj?.polycard ? POLYCARD_ITEM_ID : LEGACYCARD_ITEM_ID;

      return prop !== 'see_more' && allInterventionCards.findIndex((it) => getProperty(it, prop) === prop) !== index;
    });

    return repeatCards.map((card) => ({
      ...card?.content?.add_to_cart_item,
    }));
  }

  return [];
};

export const getRepeatProductObject = (results = []) => {
  const repeatProductObject = {};
  const duplicatedCards = [];

  const repeatProducts = results.filter((obj, index) => {
    const prop = !!obj.polycard ? 'polycard.metadata.id' : 'id';

    if (obj.id === CART_INTERVENTION || obj.id === FOUR_STARS_INTERVENTION) {
      duplicatedCards.push(...obj.dynamic_access.cards);
    }

    if (results.findIndex((it) => getProperty(it, prop) === getProperty(obj, prop)) !== index) {
      return true;
    }

    return false;
  });

  duplicatedCards.forEach((card) => {
    const item = results.find((it) => (it.polycard && card.id === it.polycard.metadata.id) || card.id === it.id);

    if (item) {
      repeatProducts.push(item);
    }
  });

  const repeatInterventionCards = getRepeatItemIntervention(results);

  if (repeatInterventionCards.length !== 0) {
    repeatInterventionCards.forEach((card) => {
      repeatProducts.push(card);
    });
  }

  // eslint-disable-next-line array-callback-return
  repeatProducts.map((it) => {
    if (it.cpg) {
      repeatProductObject[it.id] = {
        quantity: it.cpg.quantity,
        label_count: it.cpg.label_count,
        max_stock: it.available_quantity,
      };
    }

    if (it.polycard) {
      const polyId = it?.polycard?.metadata?.id;
      const polycardComponent = findPolycardComponent(it, 'add_to_cart');

      if (polycardComponent && polycardComponent.add_to_cart) {
        repeatProductObject[polyId] = {
          quantity: polycardComponent?.add_to_cart?.cart_status?.quantity,
          label_count: {
            text:
              polycardComponent?.add_to_cart?.cart_status?.format_value ||
              polycardComponent?.add_to_cart?.cart_status?.quantity,
          },
          max_stock: polycardComponent?.add_to_cart?.cart_config?.available_quantity,
        };
      }
    }
  });

  return repeatProductObject;
};

export const getItemBookmarked = (itemId) => getItemById(itemId).bookmarked;

const setItemBookmark = createSetter(({ search: { cards, results, billboard } }, { itemId }) => {
  const item = getItemById(itemId, { cards, results, billboard }, { internal: true });

  let bookmarked = get(item, 'bookmarked');

  if (Array.isArray(item.contents)) {
    item.contents.forEach((itemValue) => {
      if (itemValue.item_id === itemId) {
        bookmarked = get(itemValue, 'bookmarked');
        itemValue.bookmarked = !bookmarked;
      }
    });
  }

  item.bookmarked = !bookmarked;
});

export const toggleBookmark = (itemId) => {
  setItemBookmark({ itemId });

  return getItemBookmarked(itemId);
};

export const setSameItemList = createSetter(({ search }, payload) => {
  search.sameItemList = payload;
});

export const updateItemInSameItemList = createSetter(({ search: { sameItemList } }, { itemId, data }) => {
  const item = sameItemList[itemId];

  Object.entries(data).forEach(([property, value]) => {
    item[property] = value;
  });
});

export const setQuantityCart = createSetter(({ search: { cards, results, billboard } }, { itemId, quantity }) => {
  const item = getItemById(itemId, { cards, results, billboard }, { internal: true });

  if (item?.polycard) {
    updatePolyCartValues(item, 'cart_status', 'quantity', quantity);
    updateQuantityRepeatProduct(item, quantity, results);

    return;
  }

  item.cpg.quantity = quantity;
});

export const getLabelCount = (itemId) => {
  const item = getItemById(itemId, undefined, { internal: true });

  if (item?.polycard) {
    return findPolycardComponent(item, 'add_to_cart').add_to_cart.cart_status;
  }

  return item.cpg.label_count;
};

export const setLabelCount = createSetter(({ search: { cards, results, billboard } }, { itemId, label }) => {
  const item = getItemById(itemId, { cards, results, billboard }, { internal: true });

  if (item?.polycard) {
    updatePolyCartValues(item, 'cart_status', 'format_value', label.format_value);
    updatePolyCartValues(item, 'cart_status', 'text', label.text);

    return;
  }

  item.cpg.label_count.text = label.text;
  item.cpg.label_count.values = label.values;
});

export const setLabelCountValues = createSetter(({ search: { cards, results, billboard } }, { itemId, values }) => {
  const item = getItemById(itemId, { cards, results, billboard }, { internal: true });

  item.cpg.label_count.values = values;
});

export const getQuantityCart = (itemId, internal = false) => {
  const item = getItemById(itemId, undefined, { internal });

  if (item?.polycard) {
    return findPolycardComponent(item, 'add_to_cart').add_to_cart.cart_status.quantity;
  }

  return item.cpg.quantity;
};

export const setQuantityCartRequested = createSetter(
  ({ search: { cards, results, billboard } }, { itemId, quantity }) => {
    const item = getItemById(itemId, { cards, results, billboard }, { internal: true });

    if (item?.polycard) {
      updatePolyCartValues(item, 'cart_status', 'quantity_requested', quantity);

      return;
    }

    item.cpg.quantityRequested = quantity;
  },
);

export const getQuantityCartRequested = (itemId, internal = false) => {
  const item = getItemById(itemId, undefined, { internal });

  if (item?.polycard) {
    return findPolycardComponent(item, 'add_to_cart').add_to_cart.cart_status.quantity_requested;
  }

  return item.cpg.quantityRequested;
};

export const setQuantityCartPrevious = createSetter(
  ({ search: { cards, results, billboard } }, { itemId, quantity }) => {
    const item = getItemById(itemId, { cards, results, billboard }, { internal: true });

    if (item?.polycard) {
      updatePolyCartValues(item, 'cart_config', 'quantity_Previous', quantity);

      return;
    }

    if (item?.cpg) {
      item.cpg.quantityPrevious = quantity;
    }
  },
);

export const getQuantityCartPrevious = (itemId, internal = false) => {
  const item = getItemById(itemId, undefined, { internal });

  if (item?.polycard) {
    return findPolycardComponent(item, 'add_to_cart').add_to_cart.cart_config.quantity_Previous || 0;
  }

  return item.cpg.quantityPrevious || 0;
};

export const setPreloadedLabelCount = createSetter(
  ({ search: { cards, results, billboard } }, { itemId, preloaded }) => {
    const item = getItemById(itemId, { cards, results, billboard }, { internal: true });

    // If item is polycard, it should do nothing due to the cpg property is not used in polycard items
    if (item?.polycard) {
      return;
    }

    if (!item.cpg.preloaded_label_counts) {
      item.cpg.preloaded_label_counts = [];
    }

    preloaded.forEach((element) => {
      const position = item.cpg.preloaded_label_counts.findIndex(findSameQuantity(element.quantity));

      if (position !== -1) {
        item.cpg.preloaded_label_counts.splice(position, 1);
      }

      item.cpg.preloaded_label_counts.push(element);
    });
  },
);

export const setPartialPreloadedLabelCount = createSetter(
  ({ search: { cards, results, billboard } }, { itemId, preloaded }) => {
    const item = getItemById(itemId, { cards, results, billboard }, { internal: true });

    // If item is polycard, it should do nothing due to the cpg property is not used in polycard items
    if (item?.polycard) {
      return;
    }

    if (!item.cpg.preloaded_label_counts) {
      item.cpg.preloaded_label_counts = [];
    }

    preloaded.forEach((element) => {
      const position = item.cpg.preloaded_label_counts.findIndex((it) => it.quantity === element.quantity);

      const selectedPreloaded = item.cpg.preloaded_label_counts[position];

      if (selectedPreloaded) {
        const values = selectedPreloaded?.values.map((it) => {
          if (it.key === 'quantity_text') {
            return element.values.filter((val) => val.key === 'quantity_text')[0];
          }

          return it;
        });

        if (values.length > 0) {
          item.cpg.preloaded_label_counts[position].values = values;
        }
      } else {
        item.cpg.preloaded_label_counts.push(element);
      }
    });
  },
);

export const addLabelToPreloadLabels = createSetter(
  ({ search: { cards, results, billboard } }, { itemId, previousQuantity }) => {
    const item = getItemById(itemId, { cards, results, billboard }, { internal: true });
    const cartStatus = findPolycardComponent(item, 'add_to_cart')?.add_to_cart?.cart_status;

    cartStatus.preload_label.push({
      quantity: previousQuantity,
      format_value: cartStatus?.format_value,
    });
  },
);

export const getPreloadedLabelCount = (itemId, quantity, { previousQuantity, internal = false } = {}) => {
  const item = getItemById(itemId, undefined, { internal });

  if (item?.polycard) {
    const cartStatus = findPolycardComponent(item, 'add_to_cart')?.add_to_cart?.cart_status;
    const labelCount = cartStatus?.preload_label?.find(findSameQuantity(quantity));

    let newLabelCount;

    if (labelCount && !labelCount.text && cartStatus?.text) {
      const newText = cartStatus.text?.replace(/\(aprox\. \d+ u\.\)/, `(aprox. ${labelCount.quantity} u.)`);

      newLabelCount = {
        ...labelCount,
        text: newText,
      };

      return newLabelCount;
    }

    if (!labelCount) {
      return null;
    }

    if (previousQuantity && !cartStatus?.preload_label?.find(findSameQuantity(previousQuantity))) {
      addLabelToPreloadLabels({ itemId, previousQuantity });
    }

    return labelCount;
  }

  return item?.cpg?.preloaded_label_counts?.find(findSameQuantity(quantity)) ?? null;
};

export const isPolycardItem = (itemId) => !!getItemById(itemId, undefined, { internal: true })?.polycard;

export const getLoadedSuggestions = (itemId) => {
  const item = getItemById(itemId);

  if (item?.polycard) {
    return findPolycardComponent(item, 'add_to_cart').add_to_cart?.cart_config?.already_has_suggestions;
  }

  return item?.cpg?.alreadyHasSuggestions;
};

function removeSuggestionItemPrevious(results) {
  results.some((item) => {
    if (item?.polycard) {
      const polycardComponent = findPolycardComponent(item, 'add_to_cart');

      if (polycardComponent?.add_to_cart?.cart_config?.already_has_suggestions) {
        polycardComponent.add_to_cart.cart_config.already_has_suggestions = undefined;

        return true;
      }
    }

    return false;
  });
}

export const setLoadedSuggestions = createSetter(({ search: { cards, results, billboard } }, { itemId, value }) => {
  const item = getItemById(itemId, { cards, results, billboard }, { internal: true });

  removeSuggestionItemPrevious(results);

  if (item?.polycard) {
    updatePolyCartValues(item, 'cart_config', 'already_has_suggestions', value);

    return;
  }

  item.cpg.alreadyHasSuggestions = value;
});

export const getCPGInfo = (itemId, internal = false) => {
  const item = getItemById(itemId, undefined, { internal });
  const cpgInfo = item.cpg ?? item.polycard;

  return cpgInfo ? JSON.parse(JSON.stringify(cpgInfo)) : cpgInfo;
};
export const useCPGInfo = (itemId) => JSON.parse(JSON.stringify(getItemById(itemId, useSearch())?.cpg || {}));

export const getSuggestionsAlreadyOpened = (itemId) => {
  const item = getItemById(itemId);

  if (item?.polycard) {
    return findPolycardComponent(item, 'add_to_cart').add_to_cart?.cart_config?.suggestions_already_opened;
  }

  return item?.cpg?.suggestionsAlreadyOpened;
};

export const setSuggestionsAlreadyOpened = createSetter(
  ({ search: { cards, results, billboard } }, { itemId, value }) => {
    const item = getItemById(itemId, { cards, results, billboard }, { internal: true });

    if (item?.polycard) {
      updatePolyCartValues(item, 'cart_config', 'suggestions_already_opened', value);

      return;
    }

    item.cpg.suggestionsAlreadyOpened = value;
  },
);

export const setThresHoldPoly = createSetter(({ search: { results } }, { threshold_labels, groupBy }) => {
  results.forEach((item) => {
    const { polycard } = item || {};
    const polycardComponent = polycard && findPolycardComponent(item, 'add_to_cart');
    const { cart_shipping: cartShipping } = polycardComponent?.add_to_cart || {};
    const polyGroupBy = polycard?.metadata?.group_by;

    if (cartShipping && threshold_labels) {
      const { id_threshold, text: currentText, styles: currentStyles } = cartShipping;
      const threshold_label = threshold_labels.find(
        ({ polycard_label }) => polycard_label?.id_threshold === id_threshold,
      )?.polycard_label;

      const hasDifferentText = threshold_label?.text !== currentText;
      const hasDifferentColor = threshold_label?.styles?.color_hex !== currentStyles?.color_hex;

      if ((hasDifferentText || hasDifferentColor) && groupBy === polyGroupBy) {
        polycardComponent.add_to_cart.cart_shipping = threshold_label;
      }
    }
  });
});

export const useItemsCarousel = (items, filters) =>
  items
    ? items?.map((item, index) => {
        if (item.type === 'VIEW_MORE') {
          setCarouselItemFilters({ filters, itemIndex: index });

          // eslint-disable-next-line react-hooks/rules-of-hooks
          return useCarouselItem(index);
        }

        if (index < 4) {
          return {
            ...item,
            preload: true,
          };
        }

        return {
          ...item,
          preload: false,
        };
      })
    : [];

export const getItemMelidataTrack = (itemId) => {
  const item = getItemById(itemId, undefined, { internal: true });

  return item?.cpg?.tracks?.melidata_track || item?.polycard?.metadata?.tracks?.cart_tracks?.melidata_track;
};

export const getItemTitle = (itemId) => {
  const item = getItemById(itemId, undefined, { internal: true });

  if (item?.polycard) {
    return findPolycardComponent(item, 'title').title?.text;
  }

  return item?.title;
};

export const getItemPrice = (itemId) => {
  const item = getItemById(itemId, undefined, { internal: true });

  if (item?.polycard) {
    return findPolycardComponent(item, 'price').price?.current_price;
  }

  const { currency_id, amount } = item?.price || {};

  // Need to transform data to match the polycard format
  return {
    currency: currency_id,
    value: amount,
  };
};

export const getItemBrand = (itemId) => {
  const item = getItemById(itemId, undefined, { internal: true });

  if (item?.polycard) {
    return findPolycardComponent(item, 'brand')?.brand?.text;
  }

  return item?.brand_discoverability?.text;
};

export const getItemVariation = (itemId) => {
  const item = getItemById(itemId, undefined, { internal: true });

  return item?.polycard?.metadata?.variation_id || null;
};

export const setMelidataTrack = createSetter(
  ({ search: { cards, results, billboard } }, { itemId, path, quantity }) => {
    const item = getItemById(itemId, { cards, results, billboard }, { internal: true });

    const { melidata_track } = item?.cpg?.tracks || item?.polycard?.metadata?.tracks?.cart_tracks || {};

    if (!melidata_track) {
      return;
    }

    melidata_track.event_data.items[0].quantity = quantity;
    melidata_track.path = path;
    melidata_track.clean = true;
  },
);

export const updateItemMelidataTrack = (itemId, newTrackInfo) => {
  setMelidataTrack({ itemId, ...newTrackInfo });

  return getItemMelidataTrack(itemId);
};

export const getAlternativeComponentLabel = ({ itemId, alternative, componentIndex, search }) => {
  const itemProduct = getItemById(itemId, search, { alternative, internal: true }).product;
  const alternativeToUpdate = itemProduct[alternative.prop].find((currentAlt) => currentAlt.id === alternative.id);
  const component = alternativeToUpdate.components[componentIndex];

  return component.label;
};

export const useAlternativeComponentLabel = ({ itemId, alternative, componentIndex }) =>
  getAlternativeComponentLabel({
    itemId,
    alternative,
    componentIndex,
    search: useSearch(),
  });

export const setItemAlternativeComponentValueSize = createSetter(
  ({ search: { cards, results, billboard } }, { itemId, alternative, componentIndex, valueKey }) => {
    const label = getAlternativeComponentLabel({
      itemId,
      alternative,
      componentIndex,
      search: { cards, results, billboard },
    });
    const valueProps = label.values?.find((value) => value.key === valueKey);

    valueProps.size = valueProps.font_size;
  },
);

export const setItemAlternativeComponentSize = createSetter(
  ({ search: { cards, results, billboard } }, { itemId, alternative, componentIndex }) => {
    const label = getAlternativeComponentLabel({
      itemId,
      alternative,
      componentIndex,
      search: { cards, results, billboard },
    });

    label.size = label.font_size;
  },
);

export const setCardInterventionTrack = createSetter(
  ({ search: { cards, results, billboard } }, { itemId, cardIndex, track }) => {
    const item = getItemById(itemId, { cards, results, billboard }, { internal: true });

    const card = item?.dynamic_access?.cards?.[cardIndex];

    if (!card) {
      return;
    }

    card.content.track = track;
  },
);

export const setAddToCartItemCpgViewMode = createSetter(
  ({ search: { cards, results, billboard } }, { itemId, cardIndex, viewMode }) => {
    const item = getItemById(itemId, { cards, results, billboard }, { internal: true });

    const card = item?.dynamic_access?.cards?.[cardIndex];

    if (!card) {
      return;
    }

    card.content.add_to_cart_item.cpg.viewMode = viewMode;
  },
);

export const checkPolyCardAvailableQuantity = createSetter(
  ({ search: { cards, results, billboard } }, { itemId, finalQuantity }) => {
    const item = getItemById(itemId, { cards, results, billboard }, { internal: true });

    updatePolyCartValues(item, 'cart_config', 'max_add_quantity', finalQuantity);
    updatePolyCartValues(item, 'cart_config', 'available_quantity', finalQuantity);
    updatePolyCartValues(item, 'cart_status', 'quantity_requested', finalQuantity);
    updatePolyCartValues(item, 'cart_status', 'quantity', finalQuantity);
  },
);
